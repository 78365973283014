import { createEffect } from 'solid-js'
import { z } from 'zod'
import { createLocalSignal } from '@/lib/local'

export function storeRootDarkModeCreate() {
  const [get, set] = createLocalSignal(
    'darkMode',
    z.boolean(),
    localStorage.getItem('color-theme') === 'dark' ||
      (!('color-theme' in localStorage) &&
       window.matchMedia('(prefers-color-scheme: dark)').matches))
    createEffect(() => {
      if (get()) {
        document.documentElement.classList.add('dark')
      } else {
        document.documentElement.classList.remove('dark')
      }
    })
    return { get, set }
}
