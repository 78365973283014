import toast from "solid-toast"

export function promiseWrap<T>(promise: T): Promise<T> {
  return new Promise((resolve, reject) => {
    (promise as any).then((result: any) => {
      if (result.error != null) {
        reject(result.error)
      } else {
        resolve(result)
      }
    })
  }) as unknown as Promise<T>
}

export async function promiseToast<T>(promise: Promise<T>): Promise<T> {
  return toast.promise(promise, {
    loading: '',
    success: '',
    error: '',
  }, {
    className: 'toast-empty',
  }) as Awaited<typeof promise>
}

export function promiseWrapToast<T>(promise: T): Promise<T> {
  const p = promiseWrap(promise)
  return promiseToast(p)
}
