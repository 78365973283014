import { z } from 'zod'

export function safeParse<TSchema extends z.ZodTypeAny>(
  str: string,
  schema: TSchema,
  dfault: z.infer<TSchema>
) {
  try {
    const jsonParsed = JSON.parse(str)
    const zParsed = schema.parse(jsonParsed)
    return zParsed
  } catch (e) {
    // continue regardless of error
  }
  return dfault
}
