import { Accessor, Setter } from 'solid-js'
import { z } from 'zod'
import { supabase } from '@/supabase'
import { SupabaseEvents } from '@/types'
import { storeModelCreate } from '@/store/model'

export interface AppGetAttrs {
  app_id: string,
  app_name: string,
}

export function storeRootAppCreate(props: {
  supabaseEvents: SupabaseEvents,
  appId: Accessor<string>,
  appIdSet: Setter<string>,
}) {
  const table = 'app'
  const attrsUpdate = {
    app_name: z.string(),
  }
  const attrsCreate = {
    owner_user_id: z.string(),
    ...attrsUpdate,
  }
  const attrsKey = {
    app_id: z.string(),
  }
  const schemaKey = z.object(attrsKey)
  const schemaGet = z.object({
    ...attrsKey,
    ...attrsCreate,
  })
  const schemaFetch = z.array(schemaGet)
  const schemaBodyCreate = z.object(attrsCreate)
  const schemaBodyUpdate = z.object(attrsUpdate)
  const model = storeModelCreate({
    table, schemaKey, schemaGet, schemaFetch, schemaBodyCreate, schemaBodyUpdate,
    key: 'app_id', props,
  }, [])
  const create = async (args: z.infer<typeof schemaBodyUpdate>) => {
    const res = await supabase.rpc('app_create', args)
    const app_id = z.string().parse(res.data)
    return { app_id }
  }
  const destroy = async (args: Parameters<typeof model['destroy']>[0]) => {
    const res = await model.destroy(args)
    if (args.app_id === props.appId()) {
      props.appIdSet('')
    }
    return res
  }
  return { ...model, create, destroy }
}
