
// export function groupBy<T>(arr: T[], fn: (item: T) => any) {
//   const result: Record<string, T[]> = {}
//   for (const key in arr) {
//     const key = fn(el)
//     const els = result[key] ?? []
//     els.push(el)
//     result[key] = els
//   }
//   return result
// }
//
//

export const sortBy = (key: string) => {
  return (a: any, b: any) => ((a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0))
}

export function groupBy<T>(arr: T[], key: keyof T) {
  const map = new Map<T[keyof T], T[]>()
  for (const el of arr) {
    const val = el[key]
    const mapEl = map.get(val) ?? []
    mapEl.push(el)
    map.set(el[key], mapEl)
  }
  const get = (val: T[keyof T]) => {
    return map.get(val) ?? []
  }
  return get
}
