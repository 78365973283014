import 'solid-devtools'
import { render } from 'solid-js/web'
import { Router } from '@solidjs/router'
import { RootProvider } from '@/store'
import utc from 'dayjs/plugin/utc'
import dayjs from 'dayjs'
import { Toaster } from 'solid-toast'

dayjs.extend(utc)

const root = document.getElementById('root')

if (import.meta.env.DEV && !(root instanceof HTMLElement)) {
  throw new Error(
    'Root element not found. \
Did you forget to add it to your index.html? Or maybe the id attribute got mispelled?',
  )
}

async function run() {
  window.global ||= window
  const { default: App } = await import('./App')
  render(() => (
    <Router>
      <RootProvider>
        <App />
      </RootProvider>
      <Toaster/>
    </Router>
  ), root!)
}

run()
