import { createEffect, createMemo, createSignal, JSX } from 'solid-js'
import { z } from 'zod'
import { createLocalSignal } from '@/lib/local'
// import { useNavigate } from '@solidjs/router'

const steps = [
  'budget form',
  'budget create',
  'budget select',
  'account go',
  'account form',
  'account create',
  'budget go',
  'salary estimate form',
  'salary estimate create',
  'electricity estimate form',
  'electricity estimate create',
  'vacations estimate form',
  'vacations estimate create',
  'salary txn form',
  'salary txn create',
  'electricity txn form',
  'electricity txn create',
  'electricity target form',
  'electricity target update',
  'vacations target form',
  'vacations target update',
] as const

export type TutorialStep = typeof steps[number]
export type TutorialStepOpt = TutorialStep | TutorialStep[]

export function storeRootTutorialCreate() {
  // const navigate = useNavigate()
  /* eslint-disable sort-keys */
  const texts: Record<TutorialStep, () => JSX.Element | string> = {
    'budget form': () => 'Click here',
    'budget create': () => <>
      <div>Let's create our budget.</div>
      <div>We picked the name Main as an example.</div>
    </>,
    'budget select': () => 'Select your Budget',
      'account go': () => 'Click here',
    'account form': () => <>
      <div>Let's create our account</div>
      <div>where we keep our money</div>
    </>,
    'account create': () => "We picked the name 'Bank' with $1000 as an example.",
    'budget go': () => 'Go here',
    'salary estimate form': () => "Let's create an example salary.",
    'salary estimate create': () => "We picked the name 'Salary' with $1000 as an example.",
    'electricity estimate form': () => "Now, let's create an expense.",
    'electricity estimate create': () => "We picked 'Electricity' for $200 as an example.",
    'vacations estimate form': () => "Now, let's create a Saving.",
    'vacations estimate create': () => <>
      <div>A saving is a special type of expense</div>
      <div>that doesn't have transactions. </div>
      <div>We'll use 'Vacations' with $800 as an example.</div>
    </>,
    'salary txn form': () => <>
      <div>It's our payday!</div>
      <div>Click here to save the transaction.</div>
    </>,
    'salary txn create': () => <>
      <div>We made $1000 as an example.</div>
    </>,
    'electricity txn form': () => <>
      <div>We paid our Electricity bill today.</div>
      <div>Click here to save the transaction.</div>
    </>,
    'electricity txn create': () => <>
      <div>It costed us $160,</div>
      <div>less than the $200 we expected to pay.</div>
    </>,
    'electricity target form': () => <>
      <div>Because our electricity bill</div>
      <div>costed less than we expected,</div>
      <div>we should adjust its target.</div>
    </>,
    'electricity target update': () => <>
      <div>We'll update the target to $160.</div>
    </>,
    'vacations target form': () => <>
      <div>We have extra $40 for vacations.</div>
      <div>Let's add that to our Vacations target.</div>
    </>,
    'vacations target update': () => <>
      <div>We added $40 to our current $800</div>
    </>,
  }
  /* eslint-enable sort-keys */
  const [index, indexSet] = createSignal<number | null>()
  // const setByName = (s: TutorialStep) => {
  //   if (import.meta.env.DEV) {
  //     indexSet(steps.indexOf(s))
  //   }
  // }
  // setByName('budget go')
  const isLast = createMemo(() => index() === steps.length - 1)
  const get = createMemo<TutorialStep | null>(() => {
    const i = index()
    if (i == null) { return null }
    return steps[i] ?? null
  })
  const active = createMemo(() => get() != null)
  const start = () => indexSet(0)
  const stop = () => indexSet(null)
  const skip = stop
  const next = () => {
    const i = index()
    if (i == null) {
      indexSet(0)
      return
    }
    indexSet(i >= steps.length ? null : i + 1)
  }
  const content = (step: null | TutorialStepOpt) => {
    const current = get()
    if (step == null || current == null) { return null }
    if (Array.isArray(step)) {
      const found = step.find(s => s === current)
      if (found == null) { return null }
      return texts[found]
    }
    if (step === current) { return texts[current] }
  }
  const match = (step: null | TutorialStepOpt) => {
    return Boolean(content(step))
  }
  const [appId, appIdSet] = createLocalSignal('tutorial.appId', z.string(), '')
  return { active, appId, appIdSet, content, get, isLast, match, next, skip, start, stop }
}
