import { createStore, produce } from 'solid-js/store'
import { z } from 'zod'
import { SupabaseEvents } from '@/types'
import { supabase } from '@/supabase'
import { sortBy } from '@/lib/array'
import toast from 'solid-toast'
import { promiseWrap, promiseWrapToast } from '@/lib/promise'

export function storeModelCreate<
  TSchemaKey extends z.ZodTypeAny,
  TSchemaFetch extends z.ZodTypeAny,
  TSchemaGet extends z.ZodTypeAny,
  TSchemaBodyCreate extends z.ZodTypeAny,
  TSchemaBodyUpdate extends z.ZodTypeAny,
  TKey extends keyof z.infer<TSchemaGet>
>({ table, schemaKey, schemaFetch, schemaGet, schemaBodyCreate, schemaBodyUpdate,
    key, orderColumn, props }: {
  table: string,
  schemaKey: TSchemaKey,
  schemaFetch: TSchemaFetch,
  schemaGet: TSchemaGet,
  schemaBodyCreate: TSchemaBodyCreate,
  schemaBodyUpdate: TSchemaBodyUpdate,
  key: TKey,
  orderColumn?: string,
  props: {
    supabaseEvents: SupabaseEvents,
  }
}, defaultFetch?: z.infer<TSchemaFetch>) {
  const [all, set] = createStore<z.infer<TSchemaGet>[]>(defaultFetch ?? [])
  const hydrate = async () => {
    const { data } = await supabase.from(table).select()
    const parsed = schemaFetch.parse(data)
    if (orderColumn) {
      parsed.sort(sortBy(orderColumn))
    }
    set(parsed)
    return parsed
  }
  const insert = async (_body: z.infer<TSchemaBodyCreate>) => {
    const body = schemaBodyCreate.parse(_body)
    const promise = supabase.from(table).insert(body).select()
    const res = await promiseWrapToast(promise)
    const parsed = schemaGet.parse(res.data![0])
    set(produce(els => els.push(parsed)))
    return parsed
  }
  const update = async (
    _body: z.infer<TSchemaBodyUpdate>,
    _key: z.infer<TSchemaKey>,
  ) => {
    const body = schemaBodyUpdate.parse(_body)
    const parsedKey = schemaKey.parse(_key)
    const keyName = Object.keys(parsedKey)[0]
    if (!keyName) { return }
    const promise = supabase.from(table).update(body).eq(keyName, parsedKey[keyName])
    await promiseWrapToast(promise)
  }
  const destroy = async (_key: z.infer<TSchemaKey>) => {
    const parsedKey = schemaKey.parse(_key)
    const keyName = Object.keys(parsedKey)[0]
    if (!keyName) { return }
    const promise = supabase.from(table).delete().eq(keyName, parsedKey[keyName])
    await promiseWrapToast(promise)
  }
  const create = async (el: z.infer<TSchemaBodyCreate>) => {
    const newEl = await insert(el)
    // set([...all, newEl])
    return newEl
  }
  const find = (_key: z.infer<TSchemaKey>) => {
    return all.find((el: z.infer<TSchemaGet>) => el[key] === _key[key])
  }
  const findIndex = (_key: z.infer<TSchemaKey>) => {
    return all.findIndex((el: z.infer<TSchemaGet>) => el[key] === _key[key])
  }
  props.supabaseEvents.on(table, (payload) => {
    if (payload.eventType === 'INSERT') {
      const parsed = schemaGet.parse(payload.new)
      if (all.find(el => el[key] === parsed[key]) == null) {
        set([...all, parsed])
      }
    }
    if (payload.eventType === 'UPDATE') {
      const parsed = schemaGet.parse(payload.new)
      set(produce(els => {
        els.forEach((el, i) => {
          if (el[key] === parsed[key]) {
            els[i] = parsed
          }
        })
        return els
      }))
    }
    if (payload.eventType === 'DELETE') {
      const parsed = schemaKey.parse(payload.old)
      set(all.filter(el => el[key] !== parsed[key]))
    }
  })
  return {
    all, set, hydrate, create, update, destroy, schemaBodyCreate,
    schemaKey,
    find, findIndex, orderColumn, key,
  }
}

export type DataModel = ReturnType<typeof storeModelCreate>
