import { z } from 'zod'

const schema = z.object({
  supabase: z.object({
    anon_key: z.string(),
    url: z.string(),
  }),
})

export const env = schema.parse({
  supabase: {
    anon_key: import.meta.env.VITE_SUPABASE_ANON,
    url: import.meta.env.VITE_SUPABASE_URL,
  },
})
