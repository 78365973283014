import Emittery from 'emittery'
import { supabase, RealtimePostgresChangesPayload } from '@/supabase'

export function storeRootSupabaseEventsCreate() {
  const emt = new Emittery<Record<string, RealtimePostgresChangesPayload<any>>>()
  supabase.channel('schema-db-changes')
    .on(
      'postgres_changes',
      {
        event: '*',
        schema: 'public',
      },
      (payload) => {
        emt.emit(payload.table, payload)
      }
    )
    .subscribe()
  const on = (table: string, cb: (e: RealtimePostgresChangesPayload<any>) => void) => {
    emt.on(table, cb)
  }
  return { on }
}

export type SupabaseEvents = ReturnType<typeof storeRootSupabaseEventsCreate>
