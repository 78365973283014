import { createContext, createSignal, useContext, JSX } from 'solid-js'
import { z } from 'zod'
import { Session } from '@supabase/supabase-js'
import { createLocalSignal } from '@/lib/local'
import { supabase } from '@/supabase'
import { storeRootAppCreate } from './app'
import { storeRootDarkModeCreate } from './darkMode'
import { storeRootSupabaseEventsCreate } from './supabaseEvents'
import { storeRootTutorialCreate } from './tutorial'

interface StoreProps {
}

function createRootStore() {
  const [busy, busySet] = createSignal(true)
  const [appId, appIdSet] = createLocalSignal('appId', z.string(), '')
  const [session, sessionSet] = createSignal<Session>()
  const supabaseEvents = storeRootSupabaseEventsCreate()
  const app = storeRootAppCreate({ supabaseEvents, appId, appIdSet })
  const darkMode = storeRootDarkModeCreate()
  const tutorial = storeRootTutorialCreate()
  const hydrate = async () => {
    busySet(true)
    const [resApps, resSession] = await Promise.all([
      app.hydrate(),
      supabase.auth.getSession(),
    ])
    const sess = resSession.data?.session
    if (sess) { sessionSet(sess) }
    const [first] = resApps
    if (first && !appId()) { appIdSet(first.app_id) }
    setTimeout(() => busySet(false), 300)
  }
  const loggedIn = () => session() != null
  const logOut = async () => {
    await supabase.auth.signOut()
    sessionSet()
  }
  return {
    app, appId, appIdSet,
    darkMode,
    busy, hydrate,
    loggedIn, logOut,
    supabaseEvents, tutorial,
  }
}

type Store = ReturnType<typeof createRootStore>

export const StoreContext = createContext<Store>(null as unknown as Store)

export function RootProvider(props: StoreProps & { children: JSX.Element }) {
  const store = createRootStore()
  return <StoreContext.Provider value={store}>
    {props.children}
  </StoreContext.Provider>
}

export function storeRootUse() { return useContext(StoreContext); }
