import { createEffect, createSignal } from 'solid-js'
import { z } from 'zod'
import { safeParse } from './safeParse'

export function createLocalSignal<
  TSchema extends z.ZodTypeAny
>(
  naym: string,
  schema: TSchema,
  dfault: z.infer<TSchema>
) {
  naym = `moneti-${naym}`
  const localState = localStorage.getItem(naym)
  const [state, setState] = createSignal<z.infer<TSchema>>(
    localState ? safeParse(localState, schema, dfault) : dfault
  )
  createEffect(() => {
    localStorage.setItem(naym, JSON.stringify(state()))
  })
  return [state, setState] as const
}
